import React from "react"
import RacepageFS from "../components/racepageFS"

const BredeneKoksijde2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Bredene Koksijde Classic",
      edition: 2021,
      what: "Startlist",
      updated: "2021-03-18 17:37:45",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21797: {
        teamId: 21797,
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
        teamNationName: "Norway",
      },
      21805: {
        teamId: 21805,
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21807: {
        teamId: 21807,
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21808: {
        teamId: 21808,
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21813: {
        teamId: 21813,
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21817: {
        teamId: 21817,
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
        teamNationName: "Israel",
      },
      21819: {
        teamId: 21819,
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21824: {
        teamId: 21824,
        teamUciCode: "TQA",
        teamName: "Team Qhubeka ASSOS",
        teamNationCode: "RSA",
        teamNationName: "South Africa",
      },
      21826: {
        teamId: 21826,
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
        teamNationName: "Great Britain",
      },
      21832: {
        teamId: 21832,
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21836: {
        teamId: 21836,
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21838: {
        teamId: 21838,
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
        teamNationName: "United Arab Emirates",
      },
      21844: {
        teamId: 21844,
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21849: {
        teamId: 21849,
        teamUciCode: "BBK",
        teamName: "B&B Hotels p/b KTM",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21851: {
        teamId: 21851,
        teamUciCode: "BCF",
        teamName: "Bardiani CSF Faizan\u00e8",
        teamNationCode: "ITA",
        teamNationName: "Italy",
      },
      21852: {
        teamId: 21852,
        teamUciCode: "BWB",
        teamName: "Bingoal-WB",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21855: {
        teamId: 21855,
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21859: {
        teamId: 21859,
        teamUciCode: "DKO",
        teamName: "Delko",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21863: {
        teamId: 21863,
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21864: {
        teamId: 21864,
        teamUciCode: "ARK",
        teamName: "Team Ark\u00e9a-Samsic",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21867: {
        teamId: 21867,
        teamUciCode: "TDE",
        teamName: "Total Direct Energie",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21869: {
        teamId: 21869,
        teamUciCode: "THR",
        teamName: "Vini Zab\u00fa",
        teamNationCode: "ITA",
        teamNationName: "Italy",
      },
      23305: {
        teamId: 23305,
        teamUciCode: "TIS",
        teamName: "Tarteletto - Isorex",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      23345: {
        teamId: 23345,
        teamUciCode: "XRL",
        teamName: "Xelliss-Roubaix Lille M\u00e9tropole",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      23661: {
        teamId: 23661,
        teamUciCode: "BCY",
        teamName: "BEAT Cycling",
        teamNationCode: "NED",
        teamNationName: "Netherlands",
      },
    },
    riders: {
      1: {
        id: 18260,
        startno: 1,
        firstName: "Nils",
        lastName: "Politt",
        nationCode: "GER",
        nationName: "Germany",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      2: {
        id: 37439,
        startno: 2,
        firstName: "Patrick",
        lastName: "Gamper",
        nationCode: "AUT",
        nationName: "Austria",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      3: {
        id: 16506,
        startno: 3,
        firstName: "Martin",
        lastName: "Laas",
        nationCode: "EST",
        nationName: "Estonia",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      4: {
        id: 8388,
        startno: 4,
        firstName: "Lukas",
        lastName: "P\u00f6stlberger",
        nationCode: "AUT",
        nationName: "Austria",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      5: {
        id: 2172,
        startno: 5,
        firstName: "Juraj",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      6: {
        id: 6236,
        startno: 6,
        firstName: "R\u00fcdiger",
        lastName: "Selig",
        nationCode: "GER",
        nationName: "Germany",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      7: {
        id: 45352,
        startno: 7,
        firstName: "Matthew",
        lastName: "Walls",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      11: {
        id: 14,
        startno: 11,
        firstName: "Mark",
        lastName: "Cavendish",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      12: {
        id: 9932,
        startno: 12,
        firstName: "Shane",
        lastName: "Archbold",
        nationCode: "NZL",
        nationName: "New Zealand",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      13: {
        id: 54278,
        startno: 13,
        firstName: "Ian",
        lastName: "Garrison",
        nationCode: "USA",
        nationName: "USA",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      14: {
        id: 1298,
        startno: 14,
        firstName: "Iljo",
        lastName: "Keisse",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      15: {
        id: 12481,
        startno: 15,
        firstName: "Florian",
        lastName: "S\u00e9n\u00e9chal",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      16: {
        id: 16457,
        startno: 16,
        firstName: "Stijn",
        lastName: "Steels",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      17: {
        id: 10103,
        startno: 17,
        firstName: "Josef",
        lastName: "Cern\u00fd",
        nationCode: "CZE",
        nationName: "Czech Republic",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      21: {
        id: 66265,
        startno: 21,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      22: {
        id: 16587,
        startno: 22,
        firstName: "Alexandr",
        lastName: "Riabushenko",
        nationCode: "BLR",
        nationName: "Belarus",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      23: {
        id: 407,
        startno: 23,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      24: {
        id: 390,
        startno: 24,
        firstName: "Marco",
        lastName: "Marcato",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      25: {
        id: 34624,
        startno: 25,
        firstName: "Sebastian",
        lastName: "Molano",
        nationCode: "COL",
        nationName: "Colombia",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      26: {
        id: 37777,
        startno: 26,
        firstName: "Ivo",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      27: {
        id: 14325,
        startno: 27,
        firstName: "Rui",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      31: {
        id: 71333,
        startno: 31,
        firstName: "Alberto",
        lastName: "Dainese",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21832,
        teamName: "Team DSM",
      },
      32: {
        id: 65440,
        startno: 32,
        firstName: "Leon",
        lastName: "Heinschke",
        nationCode: "GER",
        nationName: "Germany",
        teamId: 21832,
        teamName: "Team DSM",
      },
      33: {
        id: 45368,
        startno: 33,
        firstName: "Nils",
        lastName: "Eekhoff",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21832,
        teamName: "Team DSM",
      },
      34: {
        id: 65461,
        startno: 34,
        firstName: "Niklas",
        lastName: "M\u00e4rkl",
        nationCode: "GER",
        nationName: "Germany",
        teamId: 21832,
        teamName: "Team DSM",
      },
      35: {
        id: 39096,
        startno: 35,
        firstName: "Martin",
        lastName: "Salmon",
        nationCode: "GER",
        nationName: "Germany",
        teamId: 21832,
        teamName: "Team DSM",
      },
      36: {
        id: 95140,
        startno: 36,
        firstName: "Enzo",
        lastName: "Leijnse",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21832,
        teamName: "Team DSM",
      },
      37: {
        id: 66973,
        startno: 37,
        firstName: "Andreas",
        lastName: "Leknessund",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21832,
        teamName: "Team DSM",
      },
      41: {
        id: 41249,
        startno: 41,
        firstName: "Jhonnatan",
        lastName: "Narvaez",
        nationCode: "ECU",
        nationName: "Ecuador",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      43: {
        id: 45349,
        startno: 43,
        firstName: "Ethan",
        lastName: "Hayter",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      44: {
        id: 926,
        startno: 44,
        firstName: "Michal",
        lastName: "Golas",
        nationCode: "POL",
        nationName: "Poland",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      45: {
        id: 1867,
        startno: 45,
        firstName: "Cameron",
        lastName: "Wurf",
        nationCode: "AUS",
        nationName: "Australia",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      46: {
        id: 84926,
        startno: 46,
        firstName: "Carlos",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      51: {
        id: 16793,
        startno: 51,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      53: {
        id: 7572,
        startno: 53,
        firstName: "Alexander",
        lastName: "Kamp",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      54: {
        id: 8813,
        startno: 54,
        firstName: "Emils",
        lastName: "Liepins",
        nationCode: "LAT",
        nationName: "Latvia",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      55: {
        id: 37719,
        startno: 55,
        firstName: "Matteo",
        lastName: "Moschetti",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      56: {
        id: 191,
        startno: 56,
        firstName: "Koen",
        lastName: "De Kort",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      57: {
        id: 8740,
        startno: 57,
        firstName: "Alex",
        lastName: "Kirsch",
        nationCode: "LUX",
        nationName: "Luxembourg",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      61: {
        id: 45620,
        startno: 61,
        firstName: "Alexys",
        lastName: "Brunel",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      62: {
        id: 65036,
        startno: 62,
        firstName: "Jake",
        lastName: "Stewart",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      63: {
        id: 95125,
        startno: 63,
        firstName: "Rait",
        lastName: "\u00c4rm",
        nationCode: "EST",
        nationName: "Estonia",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      64: {
        id: 93347,
        startno: 64,
        firstName: "Lewis",
        lastName: "Askey",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      65: {
        id: 37397,
        startno: 65,
        firstName: "Cl\u00e9ment",
        lastName: "Davy",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      66: {
        id: 10871,
        startno: 66,
        firstName: "Olivier",
        lastName: "Le Gac",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      67: {
        id: 12493,
        startno: 67,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      71: {
        id: 46012,
        startno: 71,
        firstName: "Filippo",
        lastName: "Conca",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      72: {
        id: 7998,
        startno: 72,
        firstName: "Matthew",
        lastName: "Holmes",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      73: {
        id: 63035,
        startno: 73,
        firstName: "S\u00e9bastien",
        lastName: "Grignard",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      74: {
        id: 51348,
        startno: 74,
        firstName: "Sylvain",
        lastName: "Moniquet",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      75: {
        id: 44622,
        startno: 75,
        firstName: "Harry",
        lastName: "Sweeny",
        nationCode: "AUS",
        nationName: "Australia",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      76: {
        id: 47124,
        startno: 76,
        firstName: "Gerben",
        lastName: "Thijssen",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      81: {
        id: 20454,
        startno: 81,
        firstName: "Simone",
        lastName: "Consonni",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21807,
        teamName: "Cofidis",
      },
      82: {
        id: 37294,
        startno: 82,
        firstName: "Szymon",
        lastName: "Sajnok",
        nationCode: "POL",
        nationName: "Poland",
        teamId: 21807,
        teamName: "Cofidis",
      },
      83: {
        id: 16690,
        startno: 83,
        firstName: "Tom",
        lastName: "Bohli",
        nationCode: "SUI",
        nationName: "Switzerland",
        teamId: 21807,
        teamName: "Cofidis",
      },
      84: {
        id: 37286,
        startno: 84,
        firstName: "Andr\u00e9",
        lastName: "Carvalho",
        nationCode: "POR",
        nationName: "Portugal",
        teamId: 21807,
        teamName: "Cofidis",
      },
      85: {
        id: 47307,
        startno: 85,
        firstName: "Emmanuel",
        lastName: "Morin",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21807,
        teamName: "Cofidis",
      },
      86: {
        id: 1276,
        startno: 86,
        firstName: "Jelle",
        lastName: "Wallays",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21807,
        teamName: "Cofidis",
      },
      91: {
        id: 14902,
        startno: 91,
        firstName: "Rudy",
        lastName: "Barbier",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      94: {
        id: 90911,
        startno: 94,
        firstName: "Taj",
        lastName: "Jones",
        nationCode: "AUS",
        nationName: "Australia",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      95: {
        id: 70759,
        startno: 95,
        firstName: "Alexis",
        lastName: "Renard",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      96: {
        id: 37384,
        startno: 96,
        firstName: "Norman",
        lastName: "Vahtra",
        nationCode: "EST",
        nationName: "Estonia",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      97: {
        id: 7579,
        startno: 97,
        firstName: "Tom",
        lastName: "Van Asbroeck",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      101: {
        id: 6559,
        startno: 101,
        firstName: "Reinardt",
        lastName: "Janse Van Rensburg",
        nationCode: "RSA",
        nationName: "South Africa",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      102: {
        id: 65304,
        startno: 102,
        firstName: "Antonio",
        lastName: "Puppio",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      103: {
        id: 6100,
        startno: 103,
        firstName: "Lasse",
        lastName: "Norman Hansen",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      104: {
        id: 45419,
        startno: 104,
        firstName: "Andreas",
        lastName: "Stokbro",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      105: {
        id: 3118,
        startno: 105,
        firstName: "Matteo",
        lastName: "Pelucchi",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      106: {
        id: 41208,
        startno: 106,
        firstName: "Dylan",
        lastName: "Sunderland",
        nationCode: "AUS",
        nationName: "Australia",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      107: {
        id: 9210,
        startno: 107,
        firstName: "Max",
        lastName: "Walscheid",
        nationCode: "GER",
        nationName: "Germany",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      111: {
        id: 39185,
        startno: 111,
        firstName: "Jasper",
        lastName: "De Plus",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      112: {
        id: 7723,
        startno: 112,
        firstName: "Wesley",
        lastName: "Kreder",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      113: {
        id: 33307,
        startno: 113,
        firstName: "Riccardo",
        lastName: "Minali",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      114: {
        id: 10948,
        startno: 114,
        firstName: "Ludwig",
        lastName: "De Winter",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      115: {
        id: 68393,
        startno: 115,
        firstName: "Th\u00e9o",
        lastName: "Delacroix",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      116: {
        id: 3187,
        startno: 116,
        firstName: "Boy",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      117: {
        id: 7730,
        startno: 117,
        firstName: "Danny",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      121: {
        id: 8377,
        startno: 121,
        firstName: "Tim",
        lastName: "Merlier",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      122: {
        id: 20832,
        startno: 122,
        firstName: "Jonas",
        lastName: "Rickaert",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      123: {
        id: 2341,
        startno: 123,
        firstName: "Laurens",
        lastName: "De Vreese",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      124: {
        id: 65447,
        startno: 124,
        firstName: "Tobias",
        lastName: "Bayer",
        nationCode: "AUT",
        nationName: "Austria",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      125: {
        id: 32571,
        startno: 125,
        firstName: "Edward",
        lastName: "Planckaert",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      126: {
        id: 57069,
        startno: 126,
        firstName: "Alexandar",
        lastName: "Richardson",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      127: {
        id: 39368,
        startno: 127,
        firstName: "Lionel",
        lastName: "Taminiaux",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      131: {
        id: 16796,
        startno: 131,
        firstName: "Thomas",
        lastName: "Boudat",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      132: {
        id: 20104,
        startno: 132,
        firstName: "Benjamin",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      133: {
        id: 76398,
        startno: 133,
        firstName: "Donavan",
        lastName: "Grondin",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      134: {
        id: 13898,
        startno: 134,
        firstName: "Christophe",
        lastName: "Noppe",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      135: {
        id: 77597,
        startno: 135,
        firstName: "Markus",
        lastName: "Pajur",
        nationCode: "EST",
        nationName: "Estonia",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      136: {
        id: 39175,
        startno: 136,
        firstName: "Alan",
        lastName: "Riou",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      137: {
        id: 37495,
        startno: 137,
        firstName: "Bram",
        lastName: "Welten",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      141: {
        id: 38289,
        startno: 141,
        firstName: "Pierre",
        lastName: "Barbier",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21859,
        teamName: "Delko",
      },
      142: {
        id: 20178,
        startno: 142,
        firstName: "Cl\u00e9ment",
        lastName: "Carisey",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21859,
        teamName: "Delko",
      },
      143: {
        id: 11191,
        startno: 143,
        firstName: "August",
        lastName: "Jensen",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21859,
        teamName: "Delko",
      },
      144: {
        id: 6536,
        startno: 144,
        firstName: "Jose",
        lastName: "Goncalves",
        nationCode: "POR",
        nationName: "Portugal",
        teamId: 21859,
        teamName: "Delko",
      },
      145: {
        id: 14778,
        startno: 145,
        firstName: "Eduard Michael",
        lastName: "Grosu",
        nationCode: "ROM",
        nationName: "Romania",
        teamId: 21859,
        teamName: "Delko",
      },
      146: {
        id: 37264,
        startno: 146,
        firstName: "Dusan",
        lastName: "Rajovic",
        nationCode: "SBA",
        nationName: "Serbia",
        teamId: 21859,
        teamName: "Delko",
      },
      147: {
        id: 7755,
        startno: 147,
        firstName: "Evaldas",
        lastName: "Siskevicius",
        nationCode: "LTU",
        nationName: "Lithuania",
        teamId: 21859,
        teamName: "Delko",
      },
      151: {
        id: 10950,
        startno: 151,
        firstName: "Frederik",
        lastName: "Backaert",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      152: {
        id: 939,
        startno: 152,
        firstName: "Bert",
        lastName: "De Backer",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      153: {
        id: 1966,
        startno: 153,
        firstName: "Jens",
        lastName: "Debusschere",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      154: {
        id: 5297,
        startno: 154,
        firstName: "Jonas",
        lastName: "Van Genechten",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      155: {
        id: 2995,
        startno: 155,
        firstName: "Kevin",
        lastName: "Reza",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      156: {
        id: 170,
        startno: 156,
        firstName: "Cyril",
        lastName: "Lemoine",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      157: {
        id: 16143,
        startno: 157,
        firstName: "Julien",
        lastName: "Morice",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      162: {
        id: 17183,
        startno: 162,
        firstName: "J\u00e9r\u00e9my",
        lastName: "Cabot",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      163: {
        id: 37800,
        startno: 163,
        firstName: "Florian",
        lastName: "Ma\u00eetre",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      164: {
        id: 32592,
        startno: 164,
        firstName: "Paul",
        lastName: "Ourselin",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      165: {
        id: 39819,
        startno: 165,
        firstName: "Marlon",
        lastName: "Gaillard",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      166: {
        id: 330,
        startno: 166,
        firstName: "Damien",
        lastName: "Gaudin",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      167: {
        id: 2326,
        startno: 167,
        firstName: "Geoffrey",
        lastName: "Soupe",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      171: {
        id: 865,
        startno: 171,
        firstName: "Timothy",
        lastName: "Dupont",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      172: {
        id: 38000,
        startno: 172,
        firstName: "Stanislaw",
        lastName: "Aniolkowski",
        nationCode: "POL",
        nationName: "Poland",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      173: {
        id: 37996,
        startno: 173,
        firstName: "Milan",
        lastName: "Menten",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      174: {
        id: 9072,
        startno: 174,
        firstName: "Boris",
        lastName: "Vall\u00e9e",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      175: {
        id: 71209,
        startno: 175,
        firstName: "Tom",
        lastName: "Paquot",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      176: {
        id: 37758,
        startno: 176,
        firstName: "Jonas",
        lastName: "Castrique",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      177: {
        id: 27086,
        startno: 177,
        firstName: "Tom",
        lastName: "Wirtgen",
        nationCode: "LUX",
        nationName: "Luxembourg",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      181: {
        id: 27230,
        startno: 181,
        firstName: "Kristoffer",
        lastName: "Halvorsen",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      182: {
        id: 48119,
        startno: 182,
        firstName: "Morten",
        lastName: "Hulgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      183: {
        id: 37403,
        startno: 183,
        firstName: "Niklas",
        lastName: "Larsen",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      184: {
        id: 57958,
        startno: 184,
        firstName: "Erik",
        lastName: "Resell",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      185: {
        id: 27232,
        startno: 185,
        firstName: "Anders",
        lastName: "Skaarseth",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      186: {
        id: 37303,
        startno: 186,
        firstName: "Rasmus",
        lastName: "Tiller",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      187: {
        id: 37304,
        startno: 187,
        firstName: "Syver",
        lastName: "W\u00e6rsted",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      191: {
        id: 45354,
        startno: 191,
        firstName: "Ruben",
        lastName: "Apers",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      192: {
        id: 54613,
        startno: 192,
        firstName: "Alex",
        lastName: "Colman",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      193: {
        id: 3162,
        startno: 193,
        firstName: "Kenny",
        lastName: "De Ketele",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      194: {
        id: 45371,
        startno: 194,
        firstName: "Jens",
        lastName: "Reynders",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      195: {
        id: 37434,
        startno: 195,
        firstName: "Jordi",
        lastName: "Warlop",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      196: {
        id: 49477,
        startno: 196,
        firstName: "Sasha",
        lastName: "Weemaes",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      197: {
        id: 38341,
        startno: 197,
        firstName: "Thimo",
        lastName: "Willems",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      201: {
        id: 45996,
        startno: 201,
        firstName: "Mattia",
        lastName: "Bevilacqua",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      202: {
        id: 65341,
        startno: 202,
        firstName: "Andrea",
        lastName: "Bartolozzi",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      203: {
        id: 47431,
        startno: 203,
        firstName: "Alessandro",
        lastName: "Iacchi",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      204: {
        id: 52551,
        startno: 204,
        firstName: "Joab",
        lastName: "Schneiter",
        nationCode: "SUI",
        nationName: "Switzerland",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      205: {
        id: 11086,
        startno: 205,
        firstName: "Davide",
        lastName: "Orrico",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      206: {
        id: 16892,
        startno: 206,
        firstName: "Wout",
        lastName: "Van Elzakker",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      207: {
        id: 16989,
        startno: 207,
        firstName: "Etienne",
        lastName: "Van Empel",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      211: {
        id: 40383,
        startno: 211,
        firstName: "Nicolas",
        lastName: "Dalla Valle",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      212: {
        id: 40963,
        startno: 212,
        firstName: "Giovanni",
        lastName: "Lonardi",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      213: {
        id: 22010,
        startno: 213,
        firstName: "Mirco",
        lastName: "Maestri",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      214: {
        id: 66440,
        startno: 214,
        firstName: "Fabio",
        lastName: "Mazzucco",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      215: {
        id: 45988,
        startno: 215,
        firstName: "Alessandro",
        lastName: "Monaco",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      217: {
        id: 50033,
        startno: 217,
        firstName: "Samuele",
        lastName: "Zoccarato",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      221: {
        id: 69866,
        startno: 221,
        firstName: "Andreas",
        lastName: "Goeman",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      222: {
        id: 15631,
        startno: 222,
        firstName: "Gianni",
        lastName: "Marchand",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      223: {
        id: 45357,
        startno: 223,
        firstName: "Thibau",
        lastName: "Verhofstadt",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      224: {
        id: 38306,
        startno: 224,
        firstName: "Alfdan",
        lastName: "De Decker",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      225: {
        id: 38318,
        startno: 225,
        firstName: "Thomas",
        lastName: "Joseph",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      226: {
        id: 20812,
        startno: 226,
        firstName: "Lennert",
        lastName: "Teugels",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      227: {
        id: 37220,
        startno: 227,
        firstName: "Enzo",
        lastName: "Wouters",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      231: {
        id: 33979,
        startno: 231,
        firstName: "Mathias",
        lastName: "De Witte",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23345,
        teamName: "Xelliss-Roubaix Lille M\u00e9tropole",
      },
      232: {
        id: 12516,
        startno: 232,
        firstName: "J\u00e9r\u00e9my",
        lastName: "Leveau",
        nationCode: "FRA",
        nationName: "France",
        teamId: 23345,
        teamName: "Xelliss-Roubaix Lille M\u00e9tropole",
      },
      233: {
        id: 39771,
        startno: 233,
        firstName: "Thomas",
        lastName: "Denis",
        nationCode: "FRA",
        nationName: "France",
        teamId: 23345,
        teamName: "Xelliss-Roubaix Lille M\u00e9tropole",
      },
      234: {
        id: 31020,
        startno: 234,
        firstName: "Maximilien",
        lastName: "Picoux",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23345,
        teamName: "Xelliss-Roubaix Lille M\u00e9tropole",
      },
      235: {
        id: 67837,
        startno: 235,
        firstName: "Valentin",
        lastName: "Tabellion",
        nationCode: "FRA",
        nationName: "France",
        teamId: 23345,
        teamName: "Xelliss-Roubaix Lille M\u00e9tropole",
      },
      236: {
        id: 20105,
        startno: 236,
        firstName: "Samuel",
        lastName: "Leroux",
        nationCode: "FRA",
        nationName: "France",
        teamId: 23345,
        teamName: "Xelliss-Roubaix Lille M\u00e9tropole",
      },
      237: {
        id: 17367,
        startno: 237,
        firstName: "Emiel",
        lastName: "Vermeulen",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23345,
        teamName: "Xelliss-Roubaix Lille M\u00e9tropole",
      },
      241: {
        id: 21908,
        startno: 241,
        firstName: "Jan-Willem",
        lastName: "Van Schip",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 23661,
        teamName: "BEAT Cycling",
      },
      242: {
        id: 27235,
        startno: 242,
        firstName: "Martijn",
        lastName: "Budding",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 23661,
        teamName: "BEAT Cycling",
      },
      243: {
        id: 45815,
        startno: 243,
        firstName: "Jules",
        lastName: "Hesters",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23661,
        teamName: "BEAT Cycling",
      },
      244: {
        id: 16670,
        startno: 244,
        firstName: "Piotr",
        lastName: "Havik",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 23661,
        teamName: "BEAT Cycling",
      },
      245: {
        id: 3935,
        startno: 245,
        firstName: "Yoeri",
        lastName: "Havik",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 23661,
        teamName: "BEAT Cycling",
      },
      246: {
        id: 38972,
        startno: 246,
        firstName: "Stefano",
        lastName: "Museeuw",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23661,
        teamName: "BEAT Cycling",
      },
      247: {
        id: 33211,
        startno: 247,
        firstName: "Yves",
        lastName: "Coolen",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23661,
        teamName: "BEAT Cycling",
      },
    },
  }
  const race = "Bredene Koksijde"
  const raceID = 123

  return (
    <RacepageFS
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default BredeneKoksijde2021
